<template>
  <v-app
    id="materialpro"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <div v-if="this.$store.state.blockPrint" class="block-print">
      <v-img contain src="@/assets/imgs/logos/logo_preto.png"></v-img>
    </div>
    <router-view />
  </v-app>
</template>

<script>
import { i18n } from "./main";

export default {
  name: "App",
  components: {},
  watch: {
    "$store.state.language": {
      deep: true,
      immediate: true,
      handler(lang) {
        if (!lang) return;
        i18n.locale = lang;
      },
    },
  },
};
</script>

<style>
div.v-application--wrap {
  background-color: #0e0e0e;
}
main.v-main {
  background-color: transparent !important;
}
.block-print {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100%;
  background: white;
  opacity: 0.5;
}
</style>
