import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import axios from "axios";
import store from './store/store';

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: { name: 'Statues', query: { page: "1" } },
      component: () => import("@/layouts/full-layout/Layout"),
      meta: {
        requiresLogin: true
      },
      children: [
        {
          name: 'Contact',
          path: '/contact',
          component: () => import("@/views/Contact"),
        },
        {
          name: "Statues",
          path: "statues",
          component: () => import("@/views/statues/Statues"),
        },
        {
          name: "Statue",
          path: "statue/:statueId",
          component: () => import("@/views/statues/Statue"),
        },
      ],
    },

    {
      name: 'ContactUs',
      path: '/contact_us',
      component: () => import("@/views/Contact"),
    },

    {
      path: "/auth",
      redirect: { name: 'Login' },
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "Login",
          path: "login",
          component: () => import("@/views/authentication/BoxedLogin"),
        }
      ],
    },
    {
      name: "Error",
      path: '*',
      component: () => import("@/views/authentication/Error"),
    }
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.beforeEach((to, from, next) => {
  let user = store.getters.GET_USER

  if (user) {
    axios.post(`${process.env.VUE_APP_BASE_URL}/validate_token`, { token: user.token })
      .then(() => store.dispatch('SET_USER', user))
      .catch(() => store.dispatch('LOGOUT'))
  }


  to.matched.some(record => {
    if (record.meta.requiresLogin) user ? next() : next({ path: '/auth' })
    else if (record.meta.requiresPermission) {
      if (user.role.role) next()
      store.getters.GET_PERMISSION(record.meta.permission) ? next() : next({ path: '/' })
    }
    else {
      next()
    }
  })
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
