import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_TOKEN)) || null,
    language: localStorage.getItem(process.env.VUE_APP_LANGUAGE) || 'pt',
    version: process.env.VUE_APP_VERSION || "0.0.0",
    blockPrint: false,
    Sidebar_drawer: null,
    SidebarColor: "black",
    navbarColor: "black",
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_LANGUAGE(state, payload) {
      state.language = payload;
    },
    SET_USER(state, payload) {
      state.user = payload
    }
  },
  actions: {
    SET_LANGUAGE({ commit }, payload) {
      localStorage.setItem("b2bIronLanguage", payload);
      commit('SET_LANGUAGE', payload)
    },
    SET_USER({ commit, dispatch }, payload) {
      if (!payload) dispatch('LOGOUT')
      axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`
      commit('SET_USER', payload)
    },
    LOGOUT({ commit }) {
      localStorage.removeItem(process.env.VUE_APP_USER_TOKEN)
      commit('SET_USER', null)
    }
  },
  getters: {
    GET_USER: state => state.user,
    GET_PERMISSION: state => (permission) => state.user.permissions.find(p => p.permission === permission),
    GET_VERSION: state => state.version,
  },
});
